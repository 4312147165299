<input
  #inputTel
  (blur)="onTouch()"
  (keydown)="onInputKeyPress($event)"
  (ngModelChange)="onChangeValue($event)"
  [(ngModel)]="phoneNumber"
  [disabled]="disabled"
  [maxLength]="maxLength"
  [placeholder]="placeholder ? placeholder + (ngControl?.errors?.required ? '*' : '') : placeholder"
  id="{{ name }}"
  type="tel" />

@if (showErrorMessage && !ngControl?.untouched && ngControl?.errors?.required && ngControl?.invalid) {
  <div class="error-labels">
    <mat-error>{{ 'utils.errors.required_field' | transloco }}</mat-error>
  </div>
}
