import { AfterViewInit, Component, ElementRef, Input, OnDestroy, Optional, Self, ViewChild } from '@angular/core';
import intlTelInput from 'intl-tel-input';
import lpn, { PhoneNumberFormat } from 'google-libphonenumber';

import { fromEvent, Subscription } from 'rxjs';
import { PhoneInputModel } from './phone-input.model';
import { ControlValueAccessor, FormsModule, NgControl } from '@angular/forms';
import { NgClass } from '@angular/common';
import { MatError } from '@angular/material/form-field';
import { TranslocoModule } from '@jsverse/transloco';

@Component({
  selector: 'app-phone-input',
  templateUrl: './phone-input.component.html',
  styleUrls: ['./phone-input.component.scss'],
  standalone: true,
  imports: [FormsModule, NgClass, MatError, TranslocoModule],
})
export class PhoneInputComponent implements ControlValueAccessor, AfterViewInit, OnDestroy {
  @Input() public maxLength = 15;
  @Input() public preferredCountries = ['it'];
  @Input() public placeholder?: string;
  @Input() public showErrorMessage = true;
  public phoneNumber?: string;
  public disabled = false;
  public name?: string | number | null;
  private readonly subscriptions = new Subscription();
  @ViewChild('inputTel') private inputTel?: ElementRef<HTMLElement>;
  private telInput?: intlTelInput.Plugin;
  private phoneUtil = lpn.PhoneNumberUtil.getInstance();
  private oldPhoneNumber?: string;
  private dialCode?: string;
  private countryCode?: string;

  constructor(@Optional() @Self() public ngControl?: NgControl) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  onChange: (value?: PhoneInputModel | null) => void = () => {};

  onTouch: () => void = () => {};

  ngAfterViewInit(): void {
    this.name = this.ngControl?.name;

    if (!this.inputTel) {
      return;
    }
    this.telInput = intlTelInput(this.inputTel.nativeElement, {
      preferredCountries: this.preferredCountries,
      separateDialCode: true,
    });
    this.onCountrySelected();

    const subscription = fromEvent(this.inputTel.nativeElement, 'countrychange').subscribe(() => {
      this.onCountrySelected();
    });

    this.subscriptions.add(subscription);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.telInput?.destroy();
  }

  writeValue(obj: PhoneInputModel): void {
    if (!obj) {
      this.phoneNumber = undefined;
      return;
    }
    this.phoneNumber = obj.number.split(' ').join('');

    if (!this.telInput) {
      return;
    }
    if (obj.countryCode) {
      this.telInput.setCountry(obj.countryCode);
    }
  }

  registerOnChange(fn: (value?: PhoneInputModel | null) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public onInputKeyPress(event: KeyboardEvent): void {
    const allowedChars = /[0-9+]/;
    const allowedCtrlChars = /[axcv]/; // Allows copy-pasting
    const allowedOtherKeys = ['ArrowLeft', 'ArrowUp', 'ArrowRight', 'ArrowDown', 'Home', 'End', 'Insert', 'Delete', 'Backspace', 'Enter'];

    if (!allowedChars.test(event.key) && !((event.ctrlKey || event.metaKey) && allowedCtrlChars.test(event.key)) && !allowedOtherKeys.includes(event.key)) {
      event.preventDefault();
    }
  }

  onChangeValue(phone: string): void {
    if (this.oldPhoneNumber === phone) {
      return;
    }
    this.oldPhoneNumber = phone;
    const response = this.createResponse(phone);
    this.onChange(response);
  }

  private onCountrySelected(): void {
    if (!this.telInput) {
      return;
    }
    const selectedCountryData = this.telInput.getSelectedCountryData();
    this.dialCode = selectedCountryData.dialCode;
    this.countryCode = selectedCountryData.iso2;
    if (!this.dialCode.startsWith('+')) {
      this.dialCode = `+${this.dialCode}`;
    }
    const response = this.createResponse();
    this.onChange(response);
  }

  private createResponse(phoneNumber: string = this.phoneNumber || ''): PhoneInputModel | null {
    if (!this.countryCode || !phoneNumber || !this.dialCode) {
      return null;
    }

    let number: lpn.PhoneNumber | undefined = undefined;
    try {
      number = this.phoneUtil.parse(phoneNumber, this.countryCode);
    } catch (e) {
      // Nope
    }
    if (!number) {
      return null;
    }
    const internationalNumber = this.phoneUtil.format(number, PhoneNumberFormat.INTERNATIONAL);
    const nationalNumber = this.phoneUtil.format(number, PhoneNumberFormat.NATIONAL);
    const e164Number = this.phoneUtil.format(number, PhoneNumberFormat.E164);

    return {
      countryCode: this.countryCode,
      dialCode: this.dialCode,
      number: nationalNumber,
      internationalNumber,
      nationalNumber,
      e164Number,
    };
  }
}
